/* common css */
p {
  word-wrap: break-word !important;
}

.info_icon img {
  cursor: pointer;
}

.radio_box,
.radio_box label {
  cursor: pointer;
}
.innerHTML p{ margin:0px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;}
/* form css */
input[type="text"],
input[type="password"],
input[type="email"],
input[type="search"],
input[type="tel"],
#country-search-box,
.form-control,
textarea {
  display: block;
  width: 100%;
  box-sizing: border-box;
  height: 60px;
  line-height: 1.42857143;
  color: #3E3F40 !important;
  border: none;
  border: 1px solid #EFEFEF;
  outline: none;
  border-radius: 10px;
  box-shadow: none;
  background: #fff 0% 0% no-repeat padding-box;
  padding: 0 18px;
  font: normal normal normal 16px Poppins;
}

.color_text {
  color: #3E3F40 !important;
}

/* form css */
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="email"]::placeholder,
input[type="tel"]::placeholder,
input[type="search"]::placeholder {
  color: #959494;
  font: normal normal 400 16px Poppins;
}

/*-webkit-appearance: none;-moz-appearance: none;appearance: none;*/
.form-group {
  position: relative;
  margin: 15px 0;
  display: inline-block;
  width: 100%;
}

.form-group select:focus {
  border: transparent;
}

.form-group input:focus {
  outline: none;
}
.greenbox.greentbox, .accordionlist li h6, .whtappliesBtn{
  word-wrap: break-word;
}
.calculatedTotal ul li p.priceName{
  width: 80%;
  text-align: left;
}

.calculatedTotal ul li p.price{
  width: 20%;
  text-align: right;
}


.form-group label {
  font-weight: 500;
  -ms-transition: all 200ms ease 0s;
  -webkit-transition: all 200ms ease 0s;
  transition: all 200ms ease 0s;
  color: #0e1217;
  letter-spacing: 1px;
  margin-bottom: 3px;
  display: block;
  margin-left: 12px;
  word-wrap: break-word;
}


.list .form-group label {
  white-space: normal;
}

.form-group label.checkbox {
  cursor: pointer;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
}


/***********************/

.forgotpswd {
  text-align: right;
}

.forgotpswd a {
  font: normal normal 600 16px/42px Poppins;
  letter-spacing: 0.14px;
  color: #e88500;
  text-decoration: none;
}

body {
  font-family: 'Poppins', sans-serif;
  color: #1b1c1d;
  margin: 0;
}

.main_content {
  display: flex;
  font: normal normal Poppins;
}

.main_content .left-side {
  background: #E1EDF7 url('assets/images/side-pattern.png') no-repeat padding-box;
  opacity: 1;
  width: 30%;
  background-size: cover;
  min-height: 100vh;
  background-position: center;

}

.main_content .container {
  width: 450px;
}

.container_internal {
  margin-top: 100px;
}

.main_content .header {
  font: normal normal 600 28px Poppins;
  color: #1b1c1d;
  margin: 0;


}

.main_content .sub_head {
  font: normal normal 500 14px Poppins;
  color: #4E4E4E;
  font-weight: 500;
}

.main_content .sub_head span {
  color: #2b3991;
}

.main_content .right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  padding: 40px;
}

/* buttons */

.button-primary {
  border: none;
  color: #fff;
  background: #2b3991 0% 0% no-repeat padding-box;
  opacity: 0.5;
}

.button-secondary {
  border: none;
  color: #A5A7B7;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #D3D4DC;
  opacity: 0.3;
}

.fillBtn {
  opacity: 1 !important;
}

.btn {
  height: 56px;
  border-radius: 12px;
  text-align: center;
  font: normal normal 600 16px Poppins;
  letter-spacing: 0.54px;
  width: 100%;
  outline: none;
  box-shadow: none !important;
  text-transform: capitalize;
}

.register_now {
  font: normal normal medium 14px/65px Poppins;
  text-align: center;
}

.register_now a {
  color: #e88500;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.back_button {
  text-decoration: none;
}

.back_button span {
  font: normal normal 500 13px/51px Poppins;
  letter-spacing: 0px;
  color: #2b3991;
  opacity: 1;
  margin-left: 5px;
  cursor: pointer;
}

.showPass {
  position: absolute;
  right: 10px;
  top: 45px;
  cursor: pointer;
}

/*  common use */
.text-center {
  text-align: center;
}

.full_lenght_right .container {
  width: calc(100% - 100px);
}

.d-flex {
  display: flex !important;
}

.field_head {
  margin-top: 10px;
  font: normal normal 500 18px Poppins;
  letter-spacing: 0.61px;
  font-size: 16px;
  color: #1b1c1d;
}

.field_value {
  font: normal normal normal 14px Poppins;
  letter-spacing: 0.48px;
  color: #4E4E4E;
}

.option-box {
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #EFEFEF;
  border-radius: 12px;
  padding: 18px 25px;
  position: relative;
  width: calc(50% - 15px);
}

.active_option {
  background: #FFF9F0 0% 0% no-repeat padding-box;
  border: 1px solid #e88500;
}

/* radio btn */
.radio {
  position: absolute;
  top: 15px;
  right: 15px;
}

input[type="radio"] {
  display: none;
}

input[type="radio"]+label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  position: relative;
  width: 20px;
  cursor: pointer;
}

input[type="radio"]:checked+label {
  border: 1px solid #e88500;
}

input[type="radio"]:checked+label:after {
  border: solid #e88500;
  border-width: 0 2px 2px 0;
  content: "";
  display: block;
  height: 8px;
  left: 7px;
  position: absolute;
  top: 4px;
  transform: rotate(45deg);
  width: 4px;
}

/* dropedown */
.dropdown-menu.show {
  width: 100%;
}

.dropdown-menu.show ul {
  list-style: none;
  padding: 0;
}

.dropdown-menu.show li {
  padding: 10px 20px;

}

.dropdown-menu.show li:hover {
  background-color: #ededed;
}

.dropdown-menu.show ul li label,
.iti__country-name,
.iti__dial-code {
  padding: 0px 0;
  text-align: left;
  font: normal normal normal 14px Poppins;
  letter-spacing: 0px;
  color: #1B1C1D;
  cursor: pointer;
  margin-bottom: 0px;
  font-size: 14px;
  white-space: unset;
}

.table-box .grlistDropDwon.dropdown-menu.show {
  border: transparent;
  border-radius: 10px;
  width: 230px;
  box-shadow: 0 1px 15px rgba(32, 33, 36, .28);
  position: absolute !important;
  left: -100px !important;
  top: -40px !important;


}

.grlistDropDwon.threedot.dropdown-menu.show .autoComplete li {
  padding: 7px 20px;
  border-bottom: 1px solid #f1f1f1;
}





.dropdown-menu {
  padding: 0px;
}

.closeSearch {
  background-size: 9px auto;
  width: 18px;
  height: 27px;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 18px;
  opacity: 0.5;
  left: 20px;
}

.closeSearch.search {
  background: url(assets/images/Search.svg) no-repeat 98% 4px;
  background-size: 16px auto;
  opacity: 1;
}

.searchBox {
  background: #F5F6F8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  margin: 10px;
}

#country-search-box,
.searchBox input[type="text"] {
  border: none !important;
  height: 40px;
  background: #F5F6F8 0% 0% no-repeat padding-box;
  padding-left: 40px;
}

.selectItem {
  line-height: 60px !important;
  font: normal normal 400 16px Poppins;
  color: #959494;
}

.form-control.date-icon {
  background: url(assets/images/ic_dropdown.svg) no-repeat calc(100% - 10px) center / 11px;
}

.dropdown.show .date-icon {
  background: url(assets/images/rev_dropdown.svg) no-repeat calc(100% - 10px) center / 11px;
}

.show .down-icon {
  transform: rotate(180deg);
}

.sub_lable {
  font: normal normal normal 12px Poppins;
  letter-spacing: 0.24px;
  color: #4E4E4E;
}

/* Calculate popup css  */

.calculate-popup {
  max-width: 50%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  min-height: 100%;
  background: #fff;
}

.calculate-popup .modal-content {
  padding: 30px 80px !important;
  box-shadow: none !important;
}


.calculate-popup .modal-content .dropdown-menu {
  border: 1px solid #EFEFEF;
  border-right: transparent;
}

.calculate-popup .modal-content .whtappliesBtn {
  margin-bottom: 15px;
}

.calculate-popup .modal-content input#inlineFormInputGroup {
  border: 1px solid #EFEFEF;
  border-left: transparent;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.calculate-popup .modal-content .calculateBtn {
  margin-top: 15px;
}

.calculate-popup .form-control {
  height: 45px !important;
}

.calculate-popup .whiteBoxIddetails .ukSingaporeBtn {
  padding: 5px 10px !important;
  display: block;
  text-align: left;
}


.modal-confirm .modal-header {
  padding: 0px !important;
}

.modal-confirm .modal-body {
  padding: 0px !important;
}

/* end */



.option_head {
  text-align: left;
  font: normal normal 600 22px Poppins;
  letter-spacing: 0.75px;
  color: #1b1c1d;
  margin-top: 50px;
}

.option_margin {
  margin-right: 30px;
}

.cnt_btn {
  width: calc(50% - 15px);
  float: right;
  margin-top: 50px;
}

.full_width .container {
  width: 100%;
}

.full_width_internal .container {
  width: 100%;
  padding: 50px 100px 0 100px;
}

.heading_box {
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
}

.plans_box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  justify-content: center;
}

.plans_box .plan_box {
  width: 300px;
  min-height: 430px;
  background: #E8F0F8 0% 0% no-repeat padding-box;
  border-radius: 18px;
  margin: 0 20px;
  padding: 25px 20px 60px;
  margin-bottom: 30px;
  position: relative;
}
.plan_box .form-group{
  position: absolute;
  bottom: 10px;
  left: 20px;
  width: auto;
  right: 20px;
}

.tag {
  border-radius: 19px;
  padding: 9px 20px;
  color: #fff !important;
  font: normal normal 500 12px Poppins;
}

.plan_box .tab_head {
  font: normal normal 600 18px Poppins;
  letter-spacing: 0.2px;
  color: #1b1c1d;
  padding: 20px 0;
  word-break: break-word;
}

.border-bottom {
  border-bottom: 1px solid #D3D4DC;
}


.border-bottom.dashed {
  border-bottom: 1px dashed #E1E7ED !important;
}

.plans_box ul,
.detail_box ul {
  padding: 0;
  list-style: none;
}

.plans_box ul li,
.detail_box ul li {
  padding: 8px 0;
  font: normal normal 500 12px Poppins;
  background: transparent url('/assets/images/ic_tick.svg') 0% 0% no-repeat padding-box;
  background-position: left 14px;
  padding-left: 20px;
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  word-wrap: break-word;
}

a {
  cursor: pointer;
}

.back_orange {
  background: #EAB063 0% 0% no-repeat padding-box;
}

.back_neon {
  background: #39C3CB 0% 0% no-repeat padding-box;
}

.back_purple {
  background: #9881DD 0% 0% no-repeat padding-box;
}

.back_red {
  background: #FA6D76 0% 0% no-repeat padding-box;
}

.head_text {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 1rem;
}

.amount {
  font: normal normal 600 22px Poppins;
  letter-spacing: 0.24px;
  color: #1b1c1d;
}

.years {
  font: normal normal normal 12px Poppins;
  letter-spacing: 0.14px;
  color: #7c7a7a;
}

.plan_box .desc {
  font: normal normal 400 12px Poppins;
  letter-spacing: 0.48px;
  color: #1b1c1d;
}

.plan_box .send_head {
  text-align: left;
  font: normal normal 600 12px Poppins;
  letter-spacing: 0.48px;
  color: #1b1c1d;
  margin-top: 50px;
}

.plan_box .mail_to {
  font: normal normal 500 12px Poppins;
  letter-spacing: 0.48px;
  color: #2b3991;
}

.summary .container {
  max-width: 800px !important;
}

.summary .container .heading_box {
  text-align: left !important;
  max-width: 100% !important;
}

.summary_box {
  background: #F4F8FC 0% 0% no-repeat padding-box;
  border-radius: 18px;
  width: 100%;
  margin-top: 30px;
  padding: 30px;
}

.summary_box .summary_head {
  font: normal normal 600 18px Poppins;
  letter-spacing: 0.68px;
  color: #1b1c1d;
}

.summary_box .detail_box {
  background: #E8F0F8 0% 0% no-repeat padding-box;
  border-radius: 18px;
  padding: 20px;
}

.summary_box .detail_box .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
}

.detail_box ul {
  padding-top: 15px;
  margin-bottom: 0;
}

.white_box {
  background: #fff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  padding: 20px;
  margin: 10px 0;
}

.white_box p {
  font: normal normal 500 14px Poppins;
  margin: 0;
  letter-spacing: 0.54px;
  color: #1b1c1d;
}

.calculation {
  color: #4E4E4E;
  font: normal normal 400 14px Poppins;
}

.calculation_total {
  color: #1b1c1d;
  font: normal normal 500 14px Poppins;
}

.calculation .value,
.calculation_total .value {
  text-align: right;
}

.summary .field,
.summary .value {
  padding: 5px 0;
}

.summary .count_btn {
  background: #2b3991 0% 0% no-repeat padding-box;
  border: 1px solid #2b3991;
  border-radius: 3px;
  padding: 6px 15px;
  font: normal normal 600 14px/21px Poppins;
  color: #fff !important;
  margin: 0 10px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 24px;
}

.slider.round:before {
  border-radius: 50%;
}

.main_header input:focus+.slider {
  box-shadow: 0 0 1px #328337;
}

.main_header input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.main_header input:checked+.slider {
  background-color: #328337;
}

.switch {
  display: inline-block;
  width: 52px;
  height: 25px;
  position: absolute;
  right: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* internal compenent */
.main {
  width: calc(100% - 280px);
  padding: 100px 30px;
  margin-left: 280px;
}

.main .head {
  font: normal normal 600 28px Poppins;
  color: #1b1c1d;
}

.pointer {
  cursor: pointer;
}

.table_content .searchBox {
  position: relative;
  max-width: 400px;
  background: #F5F6F8 0% 0% no-repeat padding-box;
  margin: 10px 0 !important;
}

.table_content .searchBox input::placeholder {
  font: normal normal 400 14px Poppins;
  letter-spacing: 0px;
  color: #b2b2b2 !important;
}

.table_content .closeSearch {
  top: 8px;
  left: 13px;
}

.table_content {
  margin-top: 30px;
}

.table_head {
  font: normal normal 600 18px Poppins;
  letter-spacing: 0.68px;
  color: #1b1c1d;
}

.table-box {
  margin-top: 30px;
}

.scroller {
  margin-bottom: 15px;
  overflow: auto;
}

.table-box table {
  width: 100%;
}


.table-box table thead {
  border-bottom: 5px solid white;
}

.table-box table thead th {
  background: #F4F8FC 0% 0% no-repeat padding-box;
  padding: 10px;
  font: normal normal 600 13px Poppins;
  letter-spacing: 0.3px;
  color: #1b1c1d;
  height: 80px;
  vertical-align: text-bottom;
}

.table-box table tbody tr {
  font: normal normal 400 12px Poppins;
  letter-spacing: 0.3px;
  color: #1b1c1d;
  height: 80px;
  border-bottom: 5px solid white;
}

.table-box table tbody tr td {
  padding: 10px;
  min-width: 100px;
}

.table-box table thead th:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-box table thead th:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table-box table tbody td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.table-box table tbody td:last-child {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.table_row_red {
  background: #F9EEEE;
}

.table_row_yellow {
  background: #FAF7E5;
}

.table_row_green {
  background: #EDFAF3;
}

.table_row_grey {
  background: #F8F9FA;
}

.radio_box {
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  position: relative;
  min-height: 50px;
  margin: 20px 0;
}

.radio_box .radio_label {
  text-align: left;
  font: normal normal 400 15px Poppins;
  letter-spacing: 0px;
  color: #4E4E4E;
  padding: 12px 35px 12px 15px;
}
.activeBorder{
  border:1px solid #e88500 !important;
}
.question_box{
  word-wrap: break-word;
}

.info {
  background: url(/assets/images/ic_info.svg) 0% 0% no-repeat padding-box;
  height: 25px;
  width: 25px;
}

p.profileName {
  word-wrap: break-word;
}

/* body.sidebar-mini */

body.sidebar-mini .page-sidebar ul.sidebar>li>a>span {
  display: none;
}

body.sidebar-mini .page-sidebar {
  width: 100px !important;
}

body.sidebar-mini .main {
  width: calc(100% - 100px) !important;
  margin-left: 100px;
}


/* ======  whatsPopup start ======== */

i.ic_red {
  width: 12px;
  height: 12px;
  background: #F31B26;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  margin-right: 5px;
}

i.ic_yellow {
  width: 12px;
  height: 12px;
  background: #F2B626;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  margin-right: 5px;
}

i.ic_green {
  width: 12px;
  height: 12px;
  background: #15AD55;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  margin-right: 5px;
}

.whatsPopup .modal-content {
  border-radius: 24px;
}

.whatsPopup .modal-content h4.modal-title {
  font-size: 20px;
  margin-bottom: 25px;
  text-align: center !important;
  color: #1B1C1D;
  font-weight: 600;
}

.whatsPopup .modal-content h6 {
  font-size: 16px;
  margin: 10px 0px;
  color: #1B1C1D;
  font-weight: 600;
}

.whatsPopup .modal-content .modal-header {
  padding: 30px 30px 15px;
}

.whatsPopup .modal-content p {
  font-size: 14px;
  font-weight: 600;
  color: #1B1C1D;

}

.whatsPopup .modal-content ul {
  margin: 0px;
  padding: 0px !important;
}


.calculate-popup.modal-confirm.modal-dialog.modal-dialog-centered.w-5 {
  align-items: unset !important;
}

.whatsPopup .modal-content ul li {
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 20px;
  position: relative;
  padding-left: 25px;
}

.whatsPopup .modal-content ul li i.ic_check {
  position: absolute;
  top: 0px;
  left: 5px;

}

.whatsPopup .modal-content .modal-body {
  padding: 40px;
}



/* ======  whatsPopup start end ======== */

/* share Popup css  */
.sharePopup .modal-content {
  border-radius: 24px;
}

.sharePopup .ic_cross {
  position: absolute;
  top: -10px !important;
  right: 0px !important;
  display: inline-block;
  vertical-align: middle;
}

.sharePopup .modal-content {
  padding: 40px !important;
  border-radius: 20px !important;
}

.sharePopup h4.text-center {
  margin-bottom: 24px;
}

.sharePopup .modal-body p.text-center {
  font-size: 14px;
}

.sharePopup input.form-control {
  height: 50px;
}

.sharePopup input.form-control.pr-5 {
  font-size: 14px;
}


/*   quitPopup    */
.question-mark {
  max-width: 120px;
}

.quitPopup .modal-content {
  padding: 40px !important;
  border-radius: 24px !important;
}

.quitPopup .modal-content .modal-body {
  padding: 0px !important;
}

.btn.active {
  border: 1px solid #B2B2B2;
  border-radius: 12px;
  color: #E61313;
}

.modal-body h6 {
  font-size: 20px;
  margin: 30px 0px 10px;
}

/* no_result_found css */

.no_result_found {
  max-width: 350px;
  margin: 40px auto;
  text-align: center;
  padding: 20px;
}

.no_result_found img {
  max-width: 250px;
  margin-bottom: 10px;
}

.no_result_found p {
  color: #3E3F40;
}

/* no_result_found css end */


/* ====== Add User Popup ======== */


.sideBar-modal {
  max-width: 60%;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  min-height: 100%;
  background: #fff;
  transition: -webkit-transform .3s ease-out;
  transition: transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  transition: transform .3s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform: translate(25%, 0%);
  transform: translate(25%, 0);
  -webkit-transform: translate(0, 0) !important;
  transform: translate(0, 0);
}

.sideBar-modal .modal-content {
  border-bottom: transparent;
}

.modal.fade.show .modal-dialog.sideBar-modal {
  transform: translate(0, 0);
}

.modal.fade .modal-dialog.sideBar-modal {
  transition: transform .3s ease-out;
  transform: translate(25%, 0);
}

.modal-lg.sideBar-modal .modal-header {
  padding: 30px 80px 0px 80px;
  border-bottom: 0px;
}

.modal-lg.sideBar-modal .modal-body .form-group label {
  color: #1B1C1D;
  margin-bottom: 10px;
}

.modal-lg.sideBar-modal .modal-body .form-group .form-control {
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  color: #3E3F40 !important;
  height: 50px;
  padding: 0px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.modal-lg.sideBar-modal .form-group .tagline {
  display: inline-block;
  font-size: 11px;
  margin: 0px 0px 10px 10px;
}

.modal-lg.sideBar-modal .modal-body {
  padding: 10px 80px 30px 80px;
}

.modal-lg.sideBar-modal h4.modal-title {
  font-size: 24px;
}

.modal-lg.sideBar-modal h5.sub-title {
  font-size: 20px;
  margin-bottom: 5px;
}

.myLicensewrap {
  margin-top: 30px;
}

.license-option {
  width: 100%;
  background: #fff 0% 0% no-repeat padding-box;
  border: 1px solid #EFEFEF;
  border-radius: 10px;
  padding: 18px 25px;
  position: relative;
  margin: 10px 0px;
  display: flex;
  justify-content: space-between;

}

.license-option li {
  display: inline-block;
  vertical-align: middle;
}

.license-option li .radio {
  top: 25px;
}

.license-option li span {
  display: block;
  font-size: 12px;
}

.license-option li strong {
  font-size: 14px;
  display: block
}

.license-option.active_option {
  background: #FFF9F0 0% 0% no-repeat padding-box;
  border: 1px solid #e88500;
}

/* button bottom space only use class name */
.bottom_space {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
}

.bottom_btn {
  margin: auto;
}

/* button bottom space only use class name */
.editrolepopup.modal-dialog.modal-lg.sideBar-modal {
  width: 40% !important;
}

.editrolepopup.modal-dialog.modal-lg.sideBar-modal .form-group.bottom_btn button {
  width: 100%;
}

.modal-dialog.modal-lg.reassignlicensePopup.sideBar-modal {
  width: 40%;
}

/* ======== pagination-wrapper css ============ */

/* .table-box {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 300px);
}

.pagination-wrapper {
  margin-top: auto;
  background: #fff;
  padding: 10px;
  text-align: right;

} */


.main {
  position: relative;
}

.pagination-wrapper {
  position: absolute;
  bottom: 0;
  right: 0px;
  background: #fff;
  padding: 10px 30px;
  text-align: right;
  width: 100%;
}

.pagination-wrapper .pagination-previous a:before {
  display: none !important;
}

.pagination-wrapper .pagination-previous.disabled:before {
  display: none !important;
}

.pagination-previous a:before {
  display: none !important;
}

.pagination-next a:after {
  display: none !important;
}

.ngx-pagination .current {

  background: #2B3991 !important;
  color: #fefefe !important;
  cursor: default !important;
  border-radius: 5px !important;
}

.ngx-pagination a {
  border: 1px solid #D3D3D3 !important;
  border-radius: 5px !important;
}

.pagination-next a {
  border: transparent !important;
}

.ngx-pagination li {
  margin-right: 5px !important;
}

.pagination-previous a {
  border: transparent !important;
}

.buyLicence.btn {
  height: 50px !important;
}


.white_box ul .li.totalprice p {
  font-weight: 600;
}


/* media query for tab and mobile */

@media(max-width: 1200px) {

  /* profile-option css  */
  .full_lenght_right .container {
    width: 100%;
  }

  .main_content .header {
    padding-top: 20px;
    font-size: 20px;
  }

  .main_content .right-side {
    padding: 40px;
  }
}

@media (max-width: 1080px) {

  .sideBar-modal {
    max-width: 80%;
  }

  .editrolepopup.modal-dialog.modal-lg.sideBar-modal {
    width: 80% !important;
  }

  .modal-dialog.modal-lg.reassignlicensePopup.sideBar-modal {
    width: 80%;
  }

  .main_content .left-side {
    background-size: contain;
    background-position: top;
  }

  .radio_box .radio_label {
    font-size: 14px;
  }

  .radio {
    top: 13px;
  }


  .modal-lg.sideBar-modal .modal-header {
    padding: 30px 40px 0px 40px;
    border-bottom: 0px;
  }

  .modal-lg.sideBar-modal .modal-body {
    padding: 10px 40px 30px 40px;
  }

}


@media (max-width: 900px) {
  .main_content {
    margin-top: 30px;
  }

  .main_content .left-side {
    display: none;
  }

  .main_content .right-side {
    width: 100%;
  }

  .ngx-otp-input {
    margin: 50px 15px 50px 0 !important;
  }

  .full_lenght_right .container {
    padding: 20px 20px;
    box-sizing: border-box;
    width: 100%;

  }

  .option_container .option-box {
    width: 100%;
  }

  .option_container {
    flex-wrap: wrap;
  }

  .option_container .option_margin {
    margin-bottom: 30px !important;
    margin-right: 0px;
  }

  .w-8 {
    min-width: 350px !important;
  }

  .main {
    width: 100%;
    margin-left: 0px;
    padding: 0px;
    padding-top: 100px;
  }


  .calculate-popup .modal-content {
    padding: 20px !important;
    box-shadow: none !important;
  }


}

.w-8 {
  min-width: 800px;
}

.iti {
  width: 100%;
}

.country-dropdown {
  max-width: 375px;
}

.search-container {
  padding: 5px;
}

.iti__country-list {
  max-height: 150px;
}

.dropdown-menu {
  max-height: 300px;
  overflow: auto;
}

.cancel {
  opacity: 1 !important;
}

.modal-confirm .modal-content {
  padding: 20px;
  border-radius: 24px;
  border: none;
  text-align: center;
  font-size: 14px;
  box-shadow: 10px 10px 20px #00000029;
}

.modal-confirm .modal-header {
  border-bottom: none;
  position: relative;
  display: block;
}

.modal-confirm .blueText {
  color: #2b3991;
}

.modal-confirm .form-group label {
  text-align: left;
}

.modal-confirm .modal-header span img {
  padding-right: 5px;
}

.modal-confirm h4 {
  text-align: center;
  font-size: 26px;
  margin: 30px 0 10px;
}

.modal-confirm .modal-body {
  color: #1b1c1d;
}

.modal-confirm .modal-footer {
  border: none;
  text-align: center;
  border-radius: 5px;
  font-size: 13px;
  padding: 10px 15px 10px;
}

.modal-confirm .modal-footer a {
  color: #999;
}

.modal-confirm .icon-box {
  width: 80px;
  height: 80px;
  margin: 0 auto;
  border-radius: 50%;
  z-index: 9;
  text-align: center;
  border: 3px solid #f15e5e;
}

.modal-confirm .icon-box i {
  color: #f15e5e;
  font-size: 46px;
  display: inline-block;
  margin-top: 13px;

}

.modal-confirm .btn,
.modal-confirm .btn:active {
  color: #fff;
  border-radius: 4px;
  background: #60c7c1;
  text-decoration: none;
  transition: all 0.4s;
  line-height: normal;
  min-width: 120px;
  border: none;
  min-height: 40px;
  border-radius: 3px;
  margin: 0 5px;
}

.modal-confirm .btn-secondary {
  background: #c1c1c1;
}

.modal-confirm .btn-secondary:hover,
.modal-confirm .btn-secondary:focus {
  background: #a8a8a8;
}

.modal-confirm .btn-danger {
  background: #f15e5e;
}

.modal-confirm .btn-danger:hover,
.modal-confirm .btn-danger:focus {
  background: #ee3535;
}

.modal-confirm .trigger-btn {
  display: inline-block;
  margin: 100px auto;
}

.modal-confirm .modal-footer {
  display: block;
}

.modal-confirm button.delete_account {
  width: 100%;
  border: 1px solid #b2b2b2;
  display: block;
  padding: 10px;
  width: 100%;
  background: transparent;
  color: #E61313;
  border-radius: 8px;
  outline: none;
}

.modal-confirm .cancel_btn {
  border: 1px solid transparent;
  display: block;
  padding: 10px;
  width: 100%;
  background: transparent;
  color: #A5A7B7;
  outline: none;
}

.iti__selected-flag {
  max-width: 50px;
}

.iti.separate-dial-code input {
  padding-left: 75px !important;
}

.No_found {
  padding-left: 12px;
}

.dropdown-menu.show ul li label,
.iti__country-name,
.iti__dial-code,
.selectItem {
  text-transform: auto;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.text-danger {
  text-align: left;
}

.img_up {
  max-width: 80px;
  cursor: pointer;
}

.dropdown-menu.country-dropdown.show {
  width: 300px !important;
}

.iti__flag {
  display: none;
}

input#country-search-box {
  padding-left: 15px !important;
}

.dropdown-menu.show ul .iti__flag {
  display: block
}


@media(max-width:900px) {
  a.menu_btn {
    display: none;
  }

  .page-sidebar {
    display: none
  }

  body.sidebar-mobile .page-sidebar {
    display: block;
    z-index: 2;
  }

  .banner {
    min-width: 100% !important;
    height: 250px !important;
  }

  img.banner_img {
    display: none
  }

  .no-insight-block {
    display: block !important;
  }

  .banner_sub_text {
    top: 90px !important;
  }

  button.btn.button-primary.insight_btn {
    top: 135px !important;
  }

  .block {
    margin-bottom: 40px !important;
    display: block !important;
  }

}


@media(max-width: 767px) {

  .main_content .right-side {
    padding: 0px;
    padding-bottom: 30px;
  }

  .btn {
    border-radius: 5px !important;
  }

  .main {
    padding: 100px 15px;
  }

  /* header new csss  */

  .left-header a {
    display: inline-block !important;
  }


  .full_width_internal .container {
    padding: 20px 20px 0 20px;
  }


  .form-group.mt-5 {
    margin-top: 0px !important;
  }

  .selectItem {
    font-size: 14px;
  }

  .quitPopup .modal-content {
    padding: 20px !important;
    border-radius: 24px !important;
  }

  .question-mark {
    max-width: 70px;
  }

  .calculate-popup {
    max-width: 100%;
  }

  /* modal css  */

  .sideBar-modal {
    max-width: 100%;
  }

  .modal-lg.sideBar-modal .modal-header {
    padding: 20px 30px 0px 30px;
    border-bottom: 0px;
  }

  .modal-lg.sideBar-modal .modal-body {
    padding: 5px 30px 20px 30px;
  }

  .editrolepopup.modal-dialog.modal-lg.sideBar-modal {
    width: 100% !important;
  }

  .modal-dialog.modal-lg.reassignlicensePopup.sideBar-modal {
    width: 100%;
  }

  .form-group.bottom_btn .btn {
    max-width: 100%;

  }

  .cnt_btn {
    width: 100%;
  }


  .blueBox ul li button {
    height: 40px;
  }

}

@media(max-width:499px) {
  .table-box .grlistDropDwon.dropdown-menu.show {
    top: auto !important;
  }

  a.headermenuBtn {
    top: 14px !important;
    left: 75px !important;
  }

  .swal2-html-container {
    font-size: 16px !important;
  }

  .swal2-icon {
    width: 60px !important;
    height: 60px !important;
  }

  .swal2-icon .swal2-icon-content {
    font-size: 3em !important;
  }

  .grlistDropDwon.dropdown-menu.show {
    width: auto;
  }

  .quitPopup .modal-content {
    width: 300px;
    margin: 0 auto;
  }


}

.tooltip-inner {
  background-color: #fff;
  color: black;
}

.No_found_data {
  text-align: center;
  margin-top: 40px;
  width: 100%;
}

.btn.text-blue {
  letter-spacing: 0.54px;
  color: #2B3991;
  text-transform: capitalize;
  border: 1px solid #2B3991;
  border-radius: 8px;
}

.modal-body.infopopup h6 {
  font-weight: 600;
}

.modal-body.infopopup p {
  font-size: 14px;
}
.Notification_msg p{
 margin-bottom: 5px;
}
.Notification_msg{
  background: rgb(215 221 255)!important;
}
.Notification_msg p, .Notification_msg h4{
  font-size: 15px;
  color: #333;
}
.pulse1 {
  display: block;
  position: absolute;
  top: 5px;
  left: 20px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #2b3991;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(50, 68, 179, 0.9);
  animation: pulse1 2s infinite;
}



.pulse1:hover {
  animation: none;
}

@-webkit-keyframes pulse1 {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(9, 173, 149, 0.9);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(9, 173, 149, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(9, 173, 149, 0);
  }
}

@keyframes pulse1 {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(9, 173, 149, 0.9);
    box-shadow: 0 0 0 0 rgba(9, 173, 149, 0.7);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(9, 173, 149, 0);
    box-shadow: 0 0 0 10px rgba(9, 173, 149, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(9, 173, 149, 0);
    box-shadow: 0 0 0 0 rgba(9, 173, 149, 0);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
    transform: translate3d(0, 0, 0);
  }
}


a.link p a:hover{
  color: #007FFF!important;
}